import { Add, Clear } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { SummaryContext } from "views/PoleViewer/provider";

type Props = {
  open: boolean;
  onClose: (value: number | null) => void;
};

export default function AddPoleComponentPopup({ open, onClose }: Props) {
  const summaryContext = useContext(SummaryContext);
  const [selectedNewComponent, setSelectedNewComponent] = useState<
    number | null
  >(null);
  const usedComponentIDs = summaryContext.components.map((c) => c.id);
  const remainingComponents = summaryContext.availableComponents.filter(
    (c) => !usedComponentIDs.includes(c.id)
  );

  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const gradingSystem = parseInt(formData.get("gradingSystem") as string);
    if (name.length > 0) {
      summaryContext.createComponent(name, gradingSystem).then((response) => {
        // Sleep for a bit to allow the context state to update
        summaryContext.addComponentToPole(
          response.newID,
          response.availableComponents
        );
        onClose(response.newID);
      });
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack p={2} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Add component</Typography>
          <IconButton onClick={() => onClose(null)}>
            <Clear />
          </IconButton>
        </Stack>
        {remainingComponents.length > 0 && (
          <Typography>Select a component to add</Typography>
        )}
        {remainingComponents.map((component) => (
          <Chip
            label={component?.name}
            onClick={() =>
              setSelectedNewComponent(
                selectedNewComponent === component.id ? null : component.id
              )
            }
            sx={{
              backgroundColor:
                component.id === selectedNewComponent ? "#1876D1" : "#DEDEDE",
              color:
                component.id === selectedNewComponent
                  ? "background.paper"
                  : "text.primary",
              "&:hover": {
                backgroundColor:
                  component.id === selectedNewComponent ? "#1E8FFF" : "#EDEDED",
                color:
                  component.id === selectedNewComponent
                    ? "background.paper"
                    : "text.primary",
              },
            }}
          />
        ))}
        {remainingComponents.length > 0 && <Divider />}
        <Accordion
          expanded={remainingComponents.length === 0 ? true : undefined}
        >
          <AccordionSummary data-testid="create-new-pole-component-accordion-summary">
            <Typography>
              {remainingComponents.length > 0
                ? "or, create a new component"
                : "Create a new component"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              <form onSubmit={onFormSubmit}>
                <TextField
                  data-testid="create-new-pole-component-name"
                  sx={{ my: 2 }}
                  label="Name"
                  autoFocus
                  name="name"
                  fullWidth
                  variant="standard"
                />
                <FormControl>
                  <FormLabel id="grading-system-label">
                    Grading System
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="grading-system-label"
                    name="gradingSystem"
                  >
                    {summaryContext.poleValueSystems.map((valueSystem) => (
                      <FormControlLabel
                        data-testid={`grading-system-${valueSystem.id}`}
                        value={valueSystem.id}
                        control={<Radio />}
                        label={valueSystem.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Button
                  color="success"
                  data-testid="create-new-pole-component-submit"
                  fullWidth
                  variant="contained"
                  type="submit"
                  startIcon={<Add />}
                >
                  Add new component
                </Button>
              </form>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Button
          onClick={() => {
            if (selectedNewComponent) {
              summaryContext.addComponentToPole(selectedNewComponent);
              onClose(selectedNewComponent);
            }
          }}
          variant="contained"
          disabled={!selectedNewComponent}
        >
          Add selected component to pole
        </Button>
      </Stack>
    </Dialog>
  );
}
