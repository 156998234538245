import React, { ReactElement, useEffect, useState, useRef } from "react";
import {
  ExpandLess,
  ExpandMore,
  UnfoldLess,
  UnfoldMore,
  ArrowCircleDown,
  ArrowCircleUp,
} from "@mui/icons-material";
import translations from "translations";
import { Pagination, Typography, Tooltip, IconButton } from "@mui/material";
import { ListViewMarker } from "interfaces";
import groupBy from "lodash/groupBy";
import PoleImageButton from "./PoleImageButton";
import ImageSection from "./ImageSection";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { useLanguage } from "hooks";
import "./style.scss";

interface Props {
  markers: ListViewMarker[];
  updateListViewMarkers: () => void;
  setListViewExpandPoleId: (array) => void;
  setListViewExpandImageId: (array) => void;
  expandPoleId;
  expandImageId;
  setListViewPage: (number) => void;
  page;
  poleImageToggle: boolean;
  imageId: number | null;
  gmap: google.maps.Map;
  mapLoading: boolean;
}

export default function ListView({
  markers,
  updateListViewMarkers,
  setListViewExpandPoleId,
  setListViewExpandImageId,
  expandPoleId,
  expandImageId,
  setListViewPage,
  page,
  poleImageToggle,
  imageId,
  gmap,
  mapLoading,
}: Props): ReactElement {
  useEffect(() => {
    updateListViewMarkers();
  }, [page, updateListViewMarkers]);
  const { language } = useLanguage();

  const [newPageFromNextImage, setnewPageFromNextImage] = useState(false);
  const [newPageFromPreviousImage, setnewPageFromPreviousImage] =
    useState(false);
  const [newPageToOldImageFromNext, setnewPageToOldImageFromNext] =
    useState(false);
  const [newPageToOldImageFromPrevious, setnewPageToOldImageFromPrevious] =
    useState(false);
  const [previousPage, setPreviousePage] = useState(-1);

  const listItems = markers.sort((a, b) => a.id - b.id);

  //useEffect to remember last page with active image. If user changes page and clicks next image, it will go back to previouse page.
  useEffect(() => {
    if (page !== previousPage && !!imageId) {
      const isImageOnPage = listItems.findIndex((x) => x.id === imageId);
      if (isImageOnPage !== -1) {
        setPreviousePage(page);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId, markers, listItems]);

  const groupedByPole = groupBy(markers, (marker) => {
    // @ts-ignore
    if (marker.client_pole_id === "") {
      // @ts-ignore
      if (marker.pole_id) {
        // @ts-ignore
        return String(marker.pole_id);
      } else {
        return "-1";
      }
    } else {
      // @ts-ignore
      if (marker.client_pole_id) {
        // @ts-ignore
        return String(marker.client_pole_id);
      } else {
        return "-1";
      }
    }
  });

  const groupedKeys = Object.keys(groupedByPole).map((k) => k || "-1");

  const goToNextImage = () => {
    if (!imageId && !mapLoading) {
      goToImage(listItems[0]);
    } else if (!!imageId && !mapLoading) {
      if (previousPage !== -1 && previousPage !== page) {
        setListViewPage(previousPage);
        setnewPageToOldImageFromNext(true);
        return;
      }
      const index = listItems.findIndex((x) => x.id === imageId);
      if (listItems.length > index + 1) {
        goToImage(listItems[index + 1]);
      } else if (page !== listItems[0]?.pages) {
        setListViewPage(page + 1);
        setnewPageFromNextImage(true);
      }
    }
  };

  const goToPreviousImage = () => {
    if (!imageId && !mapLoading) {
      goToImage(listItems[0]);
    } else if (!!imageId && !mapLoading) {
      if (previousPage !== -1 && previousPage !== page) {
        setListViewPage(previousPage);
        setnewPageToOldImageFromPrevious(true);
        return;
      }
      const index = listItems.findIndex((x) => x.id === imageId);
      if (index !== 0) {
        goToImage(listItems[index - 1]);
      } else if (page !== 1) {
        setListViewPage(page - 1);
        setnewPageFromPreviousImage(true);
      }
    }
  };

  const debouncedGoToPreviousImage = debounce(goToPreviousImage, 1000);
  const debouncedGoNextImage = debounce(goToNextImage, 1000);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 38) {
        // up arrow key
        event.preventDefault();
        debouncedGoToPreviousImage();
      } else if (event.keyCode === 40) {
        // down arrow key
        event.preventDefault();
        debouncedGoNextImage();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // remove the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    imageId,
    mapLoading,
    listItems,
    poleImageToggle,
    debouncedGoNextImage,
    debouncedGoToPreviousImage,
  ]);

  const navigate = useNavigate();
  const params = useParams();
  const missionId: string | number = params.mission;

  const refs = useRef([]); // an array of refs for each item

  const scrollToItem = (index) => {
    refs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  useEffect(() => {
    if (newPageToOldImageFromNext) {
      setnewPageToOldImageFromNext(false);
      goToNextImage();
    }
    if (newPageToOldImageFromPrevious) {
      setnewPageToOldImageFromPrevious(false);
      goToPreviousImage();
    }
    if (newPageFromNextImage) {
      setnewPageFromNextImage(false);
      goToImage(listItems[0]);
    }
    if (newPageFromPreviousImage) {
      setnewPageFromPreviousImage(false);
      goToImage(listItems[listItems.length - 1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  const goToImage = (image) => {
    if (image?.id !== undefined) {
      let index: number;
      if (!poleImageToggle) {
        if (image.client_pole_id !== null && image.client_pole_id !== "") {
          index = -parseInt(image.client_pole_id);
        } else if (image.pole_id !== null) {
          index = -image.pole_id;
        } else {
          index = 1;
        }
      } else {
        index = image.id;
      }
      scrollToItem(index);

      const imageId = image.id;
      if (window.location.pathname.includes("/annotate")) {
        navigate(`/${missionId}/${imageId}/annotate${window.location.search}`);
      } else {
        navigate(`/${missionId}/${imageId}${window.location.search}`);
      }

      gmap.panTo({ lat: image?.lat, lng: image?.lng });
      gmap.setZoom(18);
      const referenceWidth = 0.25;
      const slit = 1 - referenceWidth;
      const offset = referenceWidth - 0.5;
      const x = offset + slit / 2;
      gmap.panBy(-x * window.innerWidth, 0);
    }
  };

  const onPoleClick = (g) => {
    if (expandPoleId.includes(g)) {
      setListViewExpandPoleId(expandPoleId.filter((e) => e !== g));
    } else {
      goToImage(groupedByPole[g][0]);
      setListViewExpandPoleId([...expandPoleId, g]);
    }
  };

  const onExpandAllClick = () => {
    if (expandImageId.length > 0 || expandPoleId.length > 0) {
      setListViewExpandImageId([]);
      setListViewExpandPoleId([]);
    } else {
      setListViewExpandPoleId(groupedKeys);
      setListViewExpandImageId(listItems.map((i) => i.id));
    }
    if (imageId) {
      const image = listItems.filter((x) => x.id === imageId)[0];
      setTimeout(() => {
        goToImage(image);
      }, 1000);
    }
  };

  return (
    <>
      <div className="listViewWrapper">
        <div className="poleImageButtonContainer">
          <PoleImageButton />
          <Tooltip
            title={
              expandImageId.length > 0 || expandPoleId.length > 0
                ? translations.ListView.CollapseAll[language]
                : translations.ListView.ExpandAll[language]
            }
            placement="right"
            arrow
          >
            <IconButton
              onClick={() => {
                onExpandAllClick();
              }}
            >
              {expandImageId.length > 0 || expandPoleId.length > 0 ? (
                <UnfoldLess />
              ) : (
                <UnfoldMore />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip
            title={translations.ListView.GoToPreviousImage[language]}
            placement="right"
            arrow
          >
            <IconButton onClick={debouncedGoToPreviousImage}>
              <ArrowCircleUp />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={translations.ListView.GoToNextImage[language]}
            placement="right"
            arrow
          >
            <IconButton onClick={debouncedGoNextImage}>
              <ArrowCircleDown />
            </IconButton>
          </Tooltip>
        </div>
        {!poleImageToggle ? (
          <div className="list">
            {groupedKeys.map((g) => (
              <div key={g} className="listChild" ref={(el) => refs.current[-g]}>
                <div
                  className="listViewPoleItem"
                  style={{
                    backgroundColor:
                      groupedByPole[g]
                        .map((m) => imageId === m.id)
                        .includes(true) && "#EDB97A",
                  }}
                  onMouseUp={() => onPoleClick(g)}
                >
                  <div className="listItemStart">
                    <svg
                      width="20"
                      height="23"
                      viewBox="0 0 16 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>
                        {g === "-1"
                          ? "No pole"
                          : `Pole ${g} (${groupedByPole[g].length} images)`}
                      </title>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.64179 18L7.01881 9.46894C7.04475 8.88208 6.81137 8.31349 6.38061 7.91408L3.50746 5.25H1.69063C1.3092 5.25 1 4.9408 1 4.55938V4.55938C1 4.17795 1.3092 3.86875 1.69063 3.86875H1.73134V3.06035C1.73134 2.6853 2.03539 2.38125 2.41045 2.38125V2.38125C2.78551 2.38125 3.08955 2.6853 3.08955 3.06035V3.86875H3.8209V3.06035C3.8209 2.6853 4.12494 2.38125 4.5 2.38125V2.38125C4.87506 2.38125 5.1791 2.6853 5.1791 3.06035V3.86875H7.16418L7.3276 1.62521C7.35326 1.2728 7.64666 1 8 1V1C8.35334 1 8.64674 1.2728 8.67241 1.62521L8.83582 3.86875H10.8209V3.06035C10.8209 2.6853 11.1249 2.38125 11.5 2.38125V2.38125C11.8751 2.38125 12.1791 2.6853 12.1791 3.06035V3.86875H12.9104V3.06035C12.9104 2.6853 13.2145 2.38125 13.5896 2.38125V2.38125C13.9646 2.38125 14.2687 2.6853 14.2687 3.06035V3.86875H14.3094C14.6908 3.86875 15 4.17795 15 4.55938V4.55938C15 4.9408 14.6908 5.25 14.3094 5.25H12.3881L9.5873 7.92565C9.17563 8.31894 8.95108 8.86903 8.96994 9.43805L9.25373 18H6.64179ZM6.95522 6.84375L5.49254 5.25H7.16418V6.84375H6.95522ZM8.83582 6.84375V5.25H10.5075L9.04478 6.84375H8.83582Z"
                        fill="black"
                        stroke="black"
                        strokeWidth="0.1"
                      />
                    </svg>

                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingLeft: "10px",
                      }}
                    >
                      {g === "-1" ? "No pole" : g}
                    </Typography>
                  </div>

                  <div className="listItemEnd">
                    <Tooltip
                      title={translations.ListView.NumberOfDefects[language]}
                      placement="right"
                      arrow
                    >
                      <div className="listSeverityCount">
                        {groupedByPole[g].reduce(function (sum, key) {
                          return sum + key.severity_count;
                        }, 0)}
                      </div>
                    </Tooltip>
                    {expandPoleId.includes(g) ? (
                      <ExpandLess sx={{ paddingRight: "5px" }} />
                    ) : (
                      <ExpandMore sx={{ paddingRight: "5px" }} />
                    )}
                  </div>
                </div>
                {expandPoleId.includes(g) &&
                  groupedByPole[g]?.map((m) => (
                    <React.Fragment key={m.id}>
                      <ImageSection marker={m} />
                    </React.Fragment>
                  ))}
              </div>
            ))}
          </div>
        ) : (
          <div className="list" style={{ gap: !poleImageToggle && "5px" }}>
            {listItems.map((m) => (
              <div
                key={m.id}
                className="listChild"
                ref={(el) => refs.current[m.id]}
              >
                <ImageSection marker={m} />
              </div>
            ))}
          </div>
        )}
        <div className="paginationContainer">
          <div className="paginationWrapper">
            <Pagination
              count={listItems[0]?.pages}
              page={page}
              onChange={(e, value) => setListViewPage(value)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
