import { Typography, Stack, Divider } from "@mui/material";
import { axiosInstance } from "utils/request";
import translations from "translations";
import { useCurrentProject, useLanguage } from "hooks";
import axios from "axios";
import { getLambdaAxiosWithAuth } from "utils/request";
import KMLUploader from "components/KMLUploader";
import { getPowerlineUploadSignedURL } from "components/KMLUploader/utils";
import { IKeyPayload } from "components/KMLUploader/MetaSelector";
import { useState } from "react";
import CeleryKMLUploader, {
  ArkionGeoJson,
  getPowerlineUploadSignedURL as getCeleryPowerlineUploadSignedURL,
} from "components/CeleryKmlUploader";
import { useFlags } from "launchdarkly-react-client-sdk";

const initialKeys: IKeyPayload = {
  poleClientID: null,
  poleNote: null,
  powerlineClientID: null,
  powerlineFeedBay: null,
  powerlineTopologyColor: null,
  powerlineFeedStation: null,
};

export default function PowerlineUpload() {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const [keys, setKeys] = useState<IKeyPayload>(initialKeys);
  const { newKmlUploadPipeline } = useFlags();

  async function onApiSave(kmlData: string, kmlKeys: IKeyPayload) {
    const blob = new Blob([kmlData], { type: "kml" });
    const date = new Date();
    const filename = `${date.toISOString()}.kml`;
    const signedURL: string = await getPowerlineUploadSignedURL(
      filename,
      currentProject.id
    );

    await axios.put(signedURL, blob, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const lambdaAxios = await getLambdaAxiosWithAuth();
    await lambdaAxios.post<{ powerlines; poles }>(
      "/powerline/upload",
      {
        fileLinks: [signedURL],
        ...kmlKeys,
      },
      {
        headers: {
          MissionID: currentProject.id,
        },
      }
    );
  }

  async function onCelerySave(geoJson: ArkionGeoJson) {
    // Get a signed URL
    const signedUrl = await getCeleryPowerlineUploadSignedURL(
      currentProject.id,
      "geojson"
    );

    // Upload the GeoJSON to the signed URL
    const image_data = new FormData();
    const geoJsonString = JSON.stringify(geoJson);
    const geoJsonBlob = new Blob([geoJsonString], { type: "application/json" });
    const geoJsonFile = new File([geoJsonBlob], "geojson.json", {
      type: "application/json",
    });
    image_data.append("file", geoJsonFile);
    await axios.put(signedUrl, geoJsonFile);
    await axiosInstance.post(
      `/project/${currentProject.id}/powerline/geojson?s3_url=${signedUrl}`,
      {
        headers: {
          MissionID: currentProject.id,
        },
      }
    );
  }

  console.info(
    newKmlUploadPipeline
      ? "Using new KML upload pipeline"
      : "Using old KML upload pipeline"
  );

  return (
    <Stack spacing={2}>
      {newKmlUploadPipeline ? (
        <CeleryKMLUploader
          projectID={currentProject?.id ?? null}
          onSave={onCelerySave}
          keys={keys}
          setKeys={setKeys}
        />
      ) : (
        <KMLUploader
          projectID={currentProject?.id ?? null}
          onSave={onApiSave}
          keys={keys}
          setKeys={setKeys}
        />
      )}
      <Divider sx={{ pt: 4 }} />
      <Typography sx={{ color: "text.secondary" }}>
        {
          translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
            .Footnote[language]
        }
      </Typography>
    </Stack>
  );
}
