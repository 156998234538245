import { ObjectType } from "interfaces";
import { authorizedPost, authorizedPut, axiosInstance } from "utils/request";
import { setObjectMeta } from "state/actions";
import store from "state/store";
import { IObjectMeta } from "state/reducers/objectreducer";

export async function addObjectType(ot: ObjectType) {
  return await authorizedPost("/object/types/add", ot);
}

export async function updateObjectType(ot: ObjectType) {
  return await authorizedPut(`/object/types/${ot.id}`, ot);
}

export async function updateStore() {
  const response = await axiosInstance.get<IObjectMeta>("/object/types");
  const {
    objectTypes,
    issueCategories,
    detectedCategories,
    objectCategories,
    issueSeverities,
    workflowStatus,
  } = response.data;
  const newMeta = {
    objectTypes,
    issueCategories,
    detectedCategories,
    objectCategories,
    issueSeverities,
    workflowStatus,
  };
  store.dispatch(setObjectMeta(newMeta));
  return { objectTypes, objectCategories };
}
