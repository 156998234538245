import { useSearchParams } from "react-router-dom";

const queryKey = "bbox";

export function getBbox(searchParams: URLSearchParams): boolean {
  return !!parseInt(searchParams.get("bbox") ?? "1");
}

export function useBbox() {
  const [searchParams, setSearchParams] = useSearchParams();

  const showBoxes = getBbox(searchParams);

  const hideBbox = (value: boolean) => {
    if (!value) {
      if (searchParams.has(queryKey)) searchParams.delete(queryKey);
    } else {
      searchParams.set(queryKey, "0");
    }
    if (searchParams.has(queryKey)) {
      searchParams.delete(queryKey);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { showBoxes, hideBbox };
}
