import {
  CloseOutlined,
  Flag,
  ModeEditOutlineOutlined,
  NavigateBefore,
  NavigateNext,
  RemoveDone,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Paper, Tooltip, Typography } from "@mui/material";
import { workflow_status } from "AppConstants";
import RoleWrapper from "components/RoleHOC/wrapper";
import { getFilterActive, useDispatch, useSelector } from "hooks";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import {
  approveFalseReviewObjects,
  flagImageForReannotation,
  getFalseReviewObjects,
} from "state/actions";
import { useReviewControls } from "./hooks/reviewControls";

export default function SuperFalseReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // Get data from redux
  const reviewObjects = useSelector((state) => state.objects.falseReview);
  const filteredAnnotations = useSelector(
    (state) => state.image.filteredAnnotations
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const currentImage: number = parseInt(params.image);

  const filterActive = getFilterActive(searchParams);

  const [goToImageByIndex, currentIndex] = useReviewControls({
    unreviewed_workflow_status: workflow_status.FALSE_POSITIVE,
    tool_path: "superfalsepositive-review",
    reviewObjects: reviewObjects,
  });

  useEffect(() => {
    dispatch(getFalseReviewObjects());
  }, [filterActive]);

  if (!reviewObjects.imageIds) {
    return <></>;
  }

  let newUrl = location.pathname;
  const regex = /\/superfalsepositive-review/gi;
  newUrl = newUrl.replaceAll(regex, "");

  const KEY = "bbox";
  const ENABLED_VALUE = "0";

  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <RoleWrapper
      keyName="supervisorFalseReview"
      fallback={
        <Paper sx={{ p: 2 }}>
          <Typography>You do not have access to this feature</Typography>
        </Paper>
      }
    >
      <div className="reviewToolWrapper" style={{ marginLeft: "170px" }}>
        <div className="backgroundControll">
          <Tooltip title="Go to previous image" placement="left" arrow>
            <NavigateBefore
              className="navigationArrow"
              fontSize="large"
              sx={{ color: "#006FEB" }}
              onClick={() => goToImageByIndex(currentIndex - 1)}
            />
          </Tooltip>

          <div className="menuWrapper">
            <div className="circleWrapper">
              <div className="circle green">
                <Tooltip
                  title="Verify objects as false positives"
                  placement="top"
                  arrow
                >
                  <RemoveDone
                    htmlColor="white"
                    sx={{ color: "#ffff" }}
                    className="thumb"
                    onClick={() =>
                      dispatch(
                        approveFalseReviewObjects(
                          currentImage,
                          filteredAnnotations.flatMap((o) => parseInt(o.id)),
                          () => goToImageByIndex(currentIndex + 1)
                        )
                      )
                    }
                  />
                </Tooltip>
              </div>
              <div className="circle red">
                <Tooltip
                  title="Flag image for reannotation"
                  placement="top"
                  arrow
                >
                  <Flag
                    htmlColor="white"
                    sx={{ color: "#ffff" }}
                    className="thumb"
                    onClick={() =>
                      dispatch(
                        flagImageForReannotation(currentImage, true, () =>
                          goToImageByIndex(currentIndex + 1)
                        )
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>
            <div className="subMenuWrapper">
              <Tooltip
                title="Toggle visibility for bounding boxes. Does not change anything on the image"
                placement="bottom"
                arrow
              >
                <VisibilityOutlined
                  sx={{ color: "#ffff" }}
                  onClick={toggleBoundingBox}
                />
              </Tooltip>
              <Tooltip title="Open image editor" placement="bottom" arrow>
                <Link
                  to={`annotate${location.search}`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ModeEditOutlineOutlined sx={{ color: "#ffff" }} />
                </Link>
              </Tooltip>
              <Tooltip title="Exit review mode" placement="bottom" arrow>
                <CloseOutlined
                  sx={{ color: "#ffff" }}
                  onClick={() => {
                    navigate(`${newUrl}${location.search}`);
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <Tooltip title="Go to next image" placement="right" arrow>
            <NavigateNext
              className="navigationArrow green"
              fontSize="large"
              sx={{ color: "#006FEB" }}
              onClick={() => goToImageByIndex(currentIndex + 1)}
            />
          </Tooltip>
        </div>
        <div className="reviewImagesCount">
          {currentIndex + 1}/{reviewObjects.imageIds.length}
        </div>
      </div>
    </RoleWrapper>
  );
}
