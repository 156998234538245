import { getThinAnnotations } from "api/image/annotation";
import { useCurrentProject, useLanguage, useSelector, useBbox } from "hooks";
import { ReactElement, useEffect, useRef, useState } from "react";
import "./style.css";

interface IProps {
  onClick;
  onLoad;
  alt;
  image: number;
  src: string;
  defaultWidth;
  canLoad;
  objectTypes;
  bbox;
  skyqraft_employee: boolean;
  annotatorObjectColor: boolean;
  altProject?: null | number;
}

export default function LazyLoadImage({
  onClick,
  onLoad,
  alt,
  src,
  image,
  defaultWidth,
  canLoad,
  objectTypes,
  bbox,
  skyqraft_employee,
  annotatorObjectColor,
  altProject = null,
}: IProps): ReactElement {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const skyqraftHiddenSetting = useSelector(
    (state) => state.user.skyqraft_hidden
  );
  const { showBoxes } = useBbox();
  const [annotations, setAnnotations] = useState([]);
  const [isVisible, setVisibility] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const imageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(defaultWidth);

  // eslint-disable-next-line
  const observer = new IntersectionObserver(([entry]) => {
    setVisibility(entry.isIntersecting);
  });
  useEffect(() => {
    if (isVisible && canLoad && !!src && !!image && showBoxes) {
      getThinAnnotations(
        currentProject.id,
        image,
        skyqraftHiddenSetting,
        altProject
      ).then((annotations) => {
        const sortedRegions = [];
        for (const region of annotations) {
          const types = [];
          region.types.forEach((entry, i) => {
            const type = objectTypes.find((ot) => ot.id === entry);
            if (!!type && (!type.skyqraft_only || skyqraft_employee)) {
              types.push(entry);
            }
          });

          if (types.length > 0) {
            sortedRegions.push({
              ...region,
              types,
            });
          }
        }
        setAnnotations(sortedRegions);
      });
    }
  }, [
    isVisible,
    canLoad,
    image,
    src,
    objectTypes,
    language,
    skyqraft_employee,
    skyqraftHiddenSetting,
    altProject,
    showBoxes,
  ]);
  useEffect(() => {
    if (imageRef?.current) {
      observer.observe(imageRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  const imageLoad = (e) => {
    const { naturalHeight, height, naturalWidth } = e.target;
    setLoaded(true);
    setImageWidth((height * naturalWidth) / naturalHeight);
    onLoad(e);
  };

  const shouldDisplayImage = isVisible && (isLoaded || canLoad);

  return (
    <>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div
        style={{ width: imageWidth, height: "100%" }}
        onClick={onClick}
        ref={imageRef}
        className="lazyContainer"
      >
        {shouldDisplayImage && <img src={src} alt={alt} onLoad={imageLoad} />}
        {(!shouldDisplayImage || !isLoaded) && <div className="overlay" />}
      </div>
      {annotations.map((region, id) => {
        const objectWithColor = objectTypes.find(
          (ot) => region.types[0] === ot.id
        );
        if (!objectWithColor) {
          return null;
        }
        if (bbox) {
          const color = annotatorObjectColor
            ? objectWithColor.class_color
            : objectWithColor.color;

          return (
            <div
              key={`region-${id}`}
              style={{
                width: `${100 * region.w}%`,
                height: `${100 * region.h}%`,
                position: "absolute",
                left: `${100 * region.x}%`,
                top: `${100 * region.y}%`,
                border: `1.5px solid ${color}`,
                transform: `rotate(${region.rotation}deg)`,
                pointerEvents: "none",
              }}
            >
              <div
                style={{
                  backgroundColor: `${color}`,
                  opacity: 0.0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}
