import {
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useContext, useState, useEffect, useRef } from "react";
import { SummaryContext } from "views/PoleViewer/provider";
import PoleComponentItem from "./PoleComponentItem";
import PolePlacementItem from "./PolePlacementItem";
import Templates from "./Templates";
import { Add, ExpandMore } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import AddPoleComponentPopup from "./AddPoleComponentPopup";
import AddComponentPlacementPopup from "./AddComponentPlacementPopup";
import PoleComment from "../PoleComment";

export default function PoleContents() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [componentsExpanded, setComponentsExpanded] = useState(true);
  const [overviewExpanded, setOverviewExpanded] = useState(true);
  const [placementsExpanded, setPlacementsExpanded] = useState(false);
  const [addNewComponentDialog, setAddNewComponentDialog] = useState(false);

  const selectedComponentID = searchParams.has("componentID")
    ? parseInt(searchParams.get("componentID")!)
    : null;
  const selectedPlacementID = searchParams.has("placementID")
    ? parseInt(searchParams.get("placementID")!)
    : null;
  const setSelectedComponentID = (value: number | null) => {
    if (value) {
      searchParams.set("componentID", value.toString());
    } else {
      searchParams.delete("componentID");
    }
    setSearchParams(searchParams);
  };
  const setSelectedPlacementID = (value: number | null) => {
    if (value) {
      searchParams.set("placementID", value.toString());
    } else {
      searchParams.delete("placementID");
    }
    setSearchParams(searchParams);
  };

  const [addNewPlacementDialog, setAddNewPlacementDialog] = useState(false);
  const summaryContext = useContext(SummaryContext);

  const selectedComponent = summaryContext.components.find(
    (c) => c.id === selectedComponentID
  );

  useEffect(() => {
    if (selectedComponentID !== null) {
      setPlacementsExpanded(true);
    } else {
      setComponentsExpanded(true);
      setPlacementsExpanded(false);
    }
  }, [selectedComponentID]);

  const itemSummary = summaryContext.components
    .sort((a, b) => a.id - b.id)
    .map((c) => {
      return {
        id: c.id,
        name: c.name,
        status: c.placements.sort((a, b) => a.id - b.id),
      };
    });

  // Create a ref for the placement section
  const placementRef = useRef<HTMLDivElement>(null);

  // Function to scroll to the placement section
  const scrollToPlacement = () => {
    if (placementRef.current) {
      placementRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Paper>
      <Box>
        <Box p={2} height="fit-content">
          <Box>
            <Accordion expanded={overviewExpanded}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={() => setOverviewExpanded(!overviewExpanded)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>
                    <b>Overview</b>
                  </Typography>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Stack spacing={1}>
                  <Stack
                    sx={{
                      zIndex: 90,
                      p: 1,
                      cursor: "pointer",
                      background: "rgba(255,255,255,0.5)",
                    }}
                    alignItems="flex-start"
                  >
                    {itemSummary.length === 0 ? (
                      <Typography variant="caption">Click to report</Typography>
                    ) : (
                      <>
                        {itemSummary.map((item) => (
                          <Stack
                            key={item.id}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography sx={{ width: 100 }} variant="caption">
                              {item.name}:
                            </Typography>
                            <Stack direction="row" spacing={1}>
                              {item.status.map((status, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      width: 15,
                                      height: 15,
                                      borderRadius: "100%",
                                      backgroundColor: status.value.color,
                                    }}
                                  />
                                );
                              })}
                            </Stack>
                          </Stack>
                        ))}
                      </>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box p={2} height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Pole status</Typography>
            <Stack direction="row" alignItems="center">
              <Templates />
            </Stack>
          </Stack>

          <Box>
            <Accordion expanded={componentsExpanded}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={() => setComponentsExpanded(!componentsExpanded)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>
                    <b>Component</b>
                    <br />
                    {selectedComponent?.name}
                  </Typography>
                  <IconButton
                    data-testid="add-pole-component"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAddNewComponentDialog(true);
                    }}
                  >
                    <Add />
                  </IconButton>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Stack spacing={1}>
                  {summaryContext.components
                    .sort((a, b) => a.id - b.id)
                    .map((component) => (
                      <PoleComponentItem
                        key={component.id}
                        onClick={() => {
                          setSelectedComponentID(
                            selectedComponentID === component.id
                              ? null
                              : component.id
                          );
                          setPlacementsExpanded(true);
                          scrollToPlacement();
                        }}
                        component={component}
                        selected={component.id === selectedComponentID}
                      />
                    ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disabled={!!!selectedComponentID}
              expanded={placementsExpanded}
              ref={placementRef}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={() => {
                  setPlacementsExpanded(!placementsExpanded);
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography>
                    <b>Placement</b>
                  </Typography>
                  <IconButton
                    data-testid="add-pole-placement"
                    onClick={() => {
                      if (!!selectedComponentID) {
                        setAddNewPlacementDialog(true);
                      }
                    }}
                  >
                    <Add />
                  </IconButton>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack justifyContent="space-between" flex={1} spacing={2}>
                  <Stack spacing={1}>
                    {selectedComponent
                      ? selectedComponent.placements
                          .sort((a, b) => a.id - b.id)
                          .map((placement) => (
                            <PolePlacementItem
                              key={placement.id}
                              placement={placement}
                              componentID={selectedComponentID}
                              placementID={selectedPlacementID}
                              setSelectedPlacementID={setSelectedPlacementID}
                              valueSystem={selectedComponent.value_system_id}
                            />
                          ))
                      : null}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={true}>
              <AccordionSummary>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography>
                    <b>Pole Comment</b>
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack justifyContent="space-between" flex={1} spacing={2}>
                  <Stack spacing={1}>
                    <PoleComment />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <AddPoleComponentPopup
          open={addNewComponentDialog}
          onClose={(value: number | null) => {
            setAddNewComponentDialog(false);
            if (!!value) {
              setSelectedComponentID(value);
            }
          }}
        />
        {!!selectedComponentID && (
          <AddComponentPlacementPopup
            open={addNewPlacementDialog}
            onClose={() => {
              setAddNewPlacementDialog(false);
              setPlacementsExpanded(true);
            }}
            componentID={selectedComponentID}
          />
        )}
      </Box>
    </Paper>
  );
}
